import React from 'react';
import {Col, Image, Row} from "antd";

import './App.css';

import leebuilding from "./Photos/leebuilding.jpg"

function App() {
    return (
        <>
            <Row justify="center">
                <Col>
                    <h1>The Lee Building</h1>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <Image preview={false} src={leebuilding} height={400} width={373}/>
                </Col>
            </Row>
        </>
    );
}

export default App;
